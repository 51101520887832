import axios from "@/axios";

const base = "/api/training/";

class PreparacionSettingsService {
	async getVitamins({ status }) {
		const { data } = await axios.get(`${base}vitamins`, {
			params: { status },
		});
		return data;
	}
	async createVitamin({ name }) {
		try {
			const data = await axios.post(`${base}vitamins`, {
				name,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async editVitamin({ id, status, name }) {
		try {
			const data = await axios.put(`${base}vitamins/${id}`, {
				name,
				status,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async deleteVitamin({ id }) {
		try {
			const data = await axios.delete(`${base}vitamins/${id}`);
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async getQualities({ status }) {
		const { data } = await axios.get(`${base}qualities`, {
			params: { status },
		});
		return data;
	}
	async createQuality({ name }) {
		try {
			const data = await axios.post(`${base}qualities`, {
				name,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async editQuality({ id, status, name }) {
		try {
			const data = await axios.put(`${base}qualities/${id}`, {
				name,
				status,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async deleteQuality({ id }) {
		try {
			const data = await axios.delete(`${base}qualities/${id}`);
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	//
	async getSeasons() {
		const { data } = await axios.get(`${base}seasons`);
		return data;
	}
	async createSeason({ name, start_date, end_date }) {
		try {
			const data = await axios.post(`${base}seasons/insert-season`, {
				name,
				start_date,
				end_date,
			});
			return data;
		} catch (error) {
			error.response.data;
		}
	}
	async editSeason({ end_date, season_id }) {
		try {
			const data = await axios.put(`${base}seasons/update`, {
				end_date,
				season_id,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async editSeasonName({ name, season_id }) {
		const data = await axios.put(`${base}seasons/update-name/${season_id}`, {
			name,
		});
		return data;
	}
	//
	async getDefects({ status }) {
		const { data } = await axios.get(`${base}defects`, {
			params: { status },
		});
		return data;
	}
	async createDefect({ name }) {
		try {
			const data = await axios.post(`${base}defects`, {
				name,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async editDefect({ id, status, name }) {
		try {
			const data = await axios.put(`${base}defects/${id}`, {
				name,
				status,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async deleteDefect({ id }) {
		try {
			const data = await axios.delete(`${base}defects/${id}`);
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async getExercices({ status }) {
		const { data } = await axios.get(`${base}physical`, {
			params: { status },
		});
		return data;
	}
	async createExercice({ name }) {
		try {
			const data = await axios.post(`${base}physical`, {
				name,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async editExercice({ id, status, name }) {
		try {
			const data = await axios.put(`${base}physical/${id}`, {
				name,
				status,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async deleteExercice({ id }) {
		try {
			const data = await axios.delete(`${base}physical/${id}`);
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	//
	async getReasons({ status }) {
		const { data } = await axios.get(`${base}reasons`, {
			params: { status },
		});
		return data;
	}
	async createReason({ name }) {
		try {
			const data = await axios.post(`${base}reasons`, {
				name,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async editReason({ id, status, name }) {
		try {
			const data = await axios.put(`${base}reasons/${id}`, {
				name,
				status,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async deleteReason({ id }) {
		try {
			const data = await axios.delete(`${base}reasons/${id}`);
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	//
	async getBlocks({ season_id, specimen_id }) {
		const { data } = await axios.get(`${base}blocks/get-block-by-season/${season_id}`, {
			params: { specimen_id },
		});
		return data;
	}
	async getAvailableDays({ season_id }) {
		const { data } = await axios.get(`${base}blocks/days/${season_id}`);
		return data;
	}
	async createBlock({ name, days, season_id }) {
		try {
			const data = await axios.post(`${base}blocks/store`, {
				name,
				days,
				season_id,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async deleteBlock({ id }) {
		try {
			const data = await axios.delete(`${base}blocks/delete-block-by-id/${id}`);
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
}
export default new PreparacionSettingsService();
